import { Link, NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ROUTES from '../../constants/ROUTES';

//Import Image
import logoMain from "../../assets/images/logo.png"
import secondLogo from "../../assets/images/logo-2.png"
import React from "react";

export default function Headers() {
    const contactEmail: string = "kelioniuabc@gmail.com";
    const contactNumber: string = "+37063063091";
    const contactNumberPretty: string = "+370 630 630 91";

    const { t, i18n } = useTranslation(['header']);
    const navigate = useNavigate();

    const changeLanguage = (event: React.MouseEvent<HTMLButtonElement>) => {
        const target = event.target as HTMLButtonElement;
        i18n.changeLanguage(target.value);
    };

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const [searchInput, setSearchInput] = React.useState<string>('');
    const search = () => navigate(`${ROUTES.PACKAGES}?search=${searchInput}`)

    return (
        <>
            {/* =============== Topbar area start =============== */}
            <div className="topbar-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 tob-contact-row">
                            <div className="topbar-contact">
                                <ul>
                                    <li>
                                        <i className="bx bxs-phone" />
                                        <a href={`tel:${contactNumber}`}>{contactNumberPretty}</a>
                                    </li>

                                    <li>
                                        <i className="bx bxs-envelope" />
                                        <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6">
                            <div className="topbar-social">
                                <ul>
                                    <li>
                                        <a href={process.env.REACT_APP_FACEBOOK_URL} target="_blank" rel="noreferrer"><i className="bx bxl-facebook" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-6">
                            <div className="dropdown">
                                <button className="btn btn-link text-decoration-none dropdown-toggle text-white" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
                                    {i18n.language.toLocaleUpperCase()}
                                </button>
                                <ul className="dropdown-menu">
                                    <li><button className="dropdown-item" value="lt" onClick={changeLanguage}>LT</button></li>
                                    <li><button className="dropdown-item" value="en" onClick={changeLanguage}>EN</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* =============== Topbar area end =============== */}


            {/* ===============  header area start =============== */}
            <header>
                <div className="header-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                                <div className="navbar-wrap">
                                    <div className="logo d-flex justify-content-between">
                                        <Link to={`${process.env.PUBLIC_URL}/`} className="navbar-brand" onClick={scrollTop}><img src={logoMain} alt="" style={{ "maxHeight": "90px" }} /></Link>
                                    </div>
                                    <div className="navbar-icons">
                                        <div className="searchbar-open">
                                            <i className="flaticon-magnifier" />
                                        </div>
                                        <div className="user-dropdown-icon">
                                            <i className="flaticon-user" />
                                            <div className="account-dropdown">
                                                <ul>
                                                    <li className="account-el">
                                                        <i className="bx bx-user-pin" />
                                                        <Link to={"#"}>{t("signIn")}</Link>
                                                    </li>
                                                    <li className="account-el">
                                                        <i className="bx bxs-user-account" />
                                                        <Link to={"#"}>{t("myAccount")}</Link>
                                                    </li>
                                                    <li className="account-el">
                                                        <i className="bx bx-extension" />
                                                        <Link to={"#"}>{t("settings")}</Link>
                                                    </li>
                                                    <li className="account-el">
                                                        <i className="bx bx-log-in-circle" />
                                                        <Link to={"#"}>{t("logout")}</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="mobile-menu d-flex ">
                                            <div className="top-search-bar m-0 d-block d-xl-none">
                                            </div>
                                            <Link to={"#"} className="hamburger d-block d-xl-none">
                                                <span className="h-top" />
                                                <span className="h-middle" />
                                                <span className="h-bottom" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                <nav className="main-nav">
                                    <div className="navber-logo-sm">
                                        <img src={secondLogo} alt="" className="img-fluid" />
                                    </div>
                                    <ul>
                                        {/* <li className="has-child-menu">
                                            <Link to={"#"}>{t("home")}</Link>
                                            <i className="fl flaticon-plus">+</i>
                                            <ul className="sub-menu">
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/`} className="sub-item" onClick={scrollTop}>Home Page 1</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/home-page-2`} className="sub-item" onClick={scrollTop}>Home Page 2</NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <NavLink to={`${process.env.PUBLIC_URL}/about-us`} onClick={scrollTop} >About us</NavLink >
                                        </li>
                                        <li>
                                            <NavLink to={`${process.env.PUBLIC_URL}/destination`} onClick={scrollTop} >Destinations</NavLink>
                                        </li>
                                        <li className="has-child-menu">
                                            <Link to={"#"}>Tour Package</Link>
                                            <i className="fl flaticon-plus">+</i>
                                            <ul className="sub-menu">
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/package`} className="sub-item" onClick={scrollTop}>package</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/package-sidebar`} className="sub-item" onClick={scrollTop}>package sidebar</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/package-standard`} className="sub-item" onClick={scrollTop}>package standard</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/package-details`} className="sub-item" onClick={scrollTop}>package details</NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="has-child-menu">
                                            <Link to={"#"}>Blogs</Link>
                                            <i className="fl flaticon-plus">+</i>
                                            <ul className="sub-menu">
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/blog`} className="sub-item" onClick={scrollTop}>Blog page</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/blog-sidebar`} className="sub-item" onClick={scrollTop}>Blog sidebar</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/blog-standard`} className="sub-item" onClick={scrollTop}>Blog standard</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/blog-details`} className="sub-item" onClick={scrollTop}>blog-details </NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="has-child-menu">
                                            <Link to={"#"}>Pages</Link>
                                            <i className="fl flaticon-plus">+</i>
                                            <ul className="sub-menu">
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/gallary`} className="sub-item" onClick={scrollTop}>gallary page</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/guide`} className="sub-item" onClick={scrollTop}>guide page</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/destination`} className="sub-item" onClick={scrollTop}>destination page</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/404`} className="sub-item" onClick={scrollTop}>404 Page</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to={`${process.env.PUBLIC_URL}/faq`} className="sub-item" onClick={scrollTop}>FAQ page</NavLink>
                                                </li>
                                            </ul>
                                        </li> */}

                                        <li>
                                            <NavLink to={`${process.env.PUBLIC_URL}/`} end className="sub-item" onClick={scrollTop}>{t("home")}</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={`${process.env.PUBLIC_URL}/${ROUTES.OFFER_REQUEST}`} onClick={scrollTop} >{t("offerRequest")}</NavLink>
                                        </li>
                                    </ul>
                                    <div className="navbar-icons-2">
                                        <div className="searchbar-open">
                                            <i className="flaticon-magnifier" />
                                        </div>
                                        {/* <div className="user-dropdown-icon">
                                            <i className="flaticon-user" />
                                            <div className="account-dropdown">
                                                <ul>
                                                    <li className="account-el">
                                                        <i className="bx bx-user-pin" />
                                                        <Link to={`#`} >{t("signIn")}</Link>
                                                    </li>
                                                    <li className="account-el">
                                                        <i className="bx bxs-user-account" />
                                                        <Link to={`#`} >{t("myAccount")}</Link>
                                                    </li>
                                                    <li className="account-el">
                                                        <i className="bx bx-extension" />
                                                        <Link to={`#`} >{t("settings")}</Link>
                                                    </li>
                                                    <li className="account-el">
                                                        <i className="bx bx-log-in-circle" />
                                                        <Link to={`#`} >{t("logout")}</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="sidebar-contact">
                                        <ul>
                                            <li className="sidebar-single-contact"><i className="bx bxs-phone" />
                                                <a href={`tel:${contactNumber}`} >{contactNumberPretty}</a>
                                            </li>
                                            <li className="sidebar-single-contact"><i className="bx bxs-envelope" />
                                                <a href={`mailto:${contactEmail}`} >{contactEmail}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <form>
                        <div className="main-searchbar">
                            <div className="searchbar-close">
                                <i className="bx bx-x" />
                            </div>
                            <input type="text" placeholder={t("search")} onChange={(e) => setSearchInput(e.target.value)} />
                            <div className="searchbar-icon" onClick={() => search()}>
                                <i className="bx bx-search" />
                            </div>
                        </div>
                    </form>

                </div>
            </header>
            {/* ===============  header area end =============== */}
        </>
    );
}
