import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import $ from "jquery";

//Import Image
import secondLogo from "../../assets/images/logo-2.png"
import partnerImage1 from "../../assets/images/partners/1.jpg"
import partnerImage2 from "../../assets/images/partners/2.jpg"
import partnerImage3 from "../../assets/images/partners/3.png"
import partnerImage4 from "../../assets/images/partners/4.png"
import partnerImage5 from "../../assets/images/partners/5.jpg"
import partnerImage6 from "../../assets/images/partners/6.png"
import partnerImage7 from "../../assets/images/partners/7.png"
import partnerImage8 from "../../assets/images/partners/8.png"
import partnerImage9 from "../../assets/images/partners/9.png"
import partnerImage10 from "../../assets/images/partners/10.png"
import partnerImage11 from "../../assets/images/partners/11.png"

export default function Footers() {
    const { t } = useTranslation(['footer']);

    //Init Js Scripts
    useEffect(() => {
        $(document).ready(function () {
            // mobile menu
            $('.hamburger').on('click', function (event) {
                $(this).toggleClass('h-active');
                $('.main-nav').toggleClass('slidenav');
            });

            $('.header-home .main-nav ul li  a').on('click', function (event) {
                $('.hamburger').removeClass('h-active');
                $('.main-nav').removeClass('slidenav');
            });

            $(".main-nav .fl").on('click', function (event) {
                var $fl = $(this);
                $(this).parent().siblings().find('.sub-menu').slideUp();
                $(this).parent().siblings().find('.fl').addClass('flaticon-plus').text("+");
                if ($fl.hasClass('flaticon-plus')) {
                    $fl.removeClass('flaticon-plus').addClass('flaticon-minus').text("-");
                } else {
                    $fl.removeClass('flaticon-minus').addClass('flaticon-plus').text("+");
                }
                $fl.next(".sub-menu").slideToggle();
            });


            //account dropdown
            var accountCard = document.querySelectorAll('.account-dropdown')
            var userIcon = document.querySelectorAll('.user-dropdown-icon i')

            userIcon.forEach((el) => {
                el.addEventListener('click', () => {
                    accountCard.forEach((element) => {
                        element.classList.toggle("activeCard")
                    })
                })
            });

            // Search Bar js
            var searchOpen = document.querySelectorAll('.searchbar-open i')
            var searchCard = document.querySelectorAll('.main-searchbar')
            var searchClose = document.querySelectorAll('.searchbar-close i')

            searchOpen.forEach((el) => {
                el.addEventListener('click', () => {
                    searchCard.forEach((el) => {
                        el.classList.add('activeSearch')
                    })
                })
            })
            searchClose.forEach((el) => {
                el.addEventListener('click', () => {
                    searchCard.forEach((el) => {
                        el.classList.remove('activeSearch')
                    })
                })
            });

            window.onclick = function (event) {
                searchCard.forEach((el) => {
                    if (event.target === el) {
                        el.classList.remove('activeSearch')
                    }
                });
                if (!event.target.matches('.user-dropdown-icon i')) {
                    accountCard.forEach((element) => {
                        if (element.classList.contains('activeCard')) {
                            element.classList.remove('activeCard')
                        }
                    })
                }
            };

            // sticky navabr js
            $(window).on('scroll', function () {
                var scroll = $(window).scrollTop();
                if (scroll >= 10) {
                    $(".header-area").addClass("sticky");
                } else {
                    $(".header-area").removeClass("sticky");
                }
            });

            $(".preloader").delay(1000).fadeOut("slow");

        });
    });


    const scrollTop = () =>
    {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <>
            {/* ===============  Newsletter area start =============== */}
            {/* <div className="newsletter-area pt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="newsletter-wrapper">
                                <h2>{t("subNewsletter")}</h2>
                                <form>
                                    <div className="input-group newsletter-input">
                                        <input type="text" className="form-control" placeholder={t("enterYourEmail")} aria-label={t("enterYourEmail")} aria-describedby="button-addon2" />
                                        <button className="btn btn-outline-secondary" type="button" id="button-addon2">{t("subscribe")}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* ===============  Newsletter area end =============== */}

            {/* ==============  Footer area start================= */}
            <div className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="footer-info">
                                <div className="footer-logo">
                                    <img src={secondLogo} alt="" className="img-fluid" />
                                </div>
                                <div className="footer-social-icons">
                                    <h5>{t("followUs")}:</h5>
                                    <ul>
                                        <li>
                                            <a href={process.env.REACT_APP_FACEBOOK_URL} target="_blank" rel="noreferrer"><i className="bx bxl-facebook" /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-7">
                                    <div className="footer-links">
                                        <h5 className="widget-title">{t("contactUs")}</h5>
                                        <div className="contact-box">
                                            <span><i className="bx bx-phone" /></span>
                                            <div>
                                                <a href="tel:+37063063091">+370 630 630 91</a>
                                            </div>
                                        </div>
                                        <div className="contact-box">
                                            <span><i className="bx bx-mail-send" /></span>
                                            <div>
                                                <a href="mailto:kelioniuabc@gmail.com">kelioniuabc@gmail.com</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-5">
                                    <div className="footer-links">
                                        <h5 className="widget-title">{t("links")}</h5>
                                        <div className="category-list">
                                            <ul>
                                                <li>
                                                    <Link to={`${process.env.PUBLIC_URL}/offerRequest`} onClick={scrollTop} >{t("getOffer")}</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="footer-links payment-links">
                                        <h5 className="widget-title">{t("partners")}:</h5>
                                        <div className="payment-cards">
                                            <img src={partnerImage2} alt="" className="img-fluid" />
                                            <img src={partnerImage1} alt="" className="img-fluid" />
                                            <img src={partnerImage3} alt="" className="img-fluid" />
                                            <img src={partnerImage4} alt="" className="img-fluid" />
                                            <img src={partnerImage5} alt="" className="img-fluid" />
                                            <img src={partnerImage6} alt="" className="img-fluid" />
                                            <img src={partnerImage7} alt="" className="img-fluid" />
                                            <img src={partnerImage8} alt="" className="img-fluid" />
                                            <img src={partnerImage9} alt="" className="img-fluid" />
                                            <img src={partnerImage10} alt="" className="img-fluid" />
                                            <img src={partnerImage11} alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="copyrigth-area">
                                <p>{t("copyright")} {new Date().getFullYear()} <Link to={`#`}>KelioniuABC</Link>. {t("allRightsReserved")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ==============  Footer area end================= */}
        </>
    );
}
