import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../../../assets/css/checkMark.css';
import RequestForm from "./RequestForm";

export default function ContactPage() {
    const { t } = useTranslation(['requestForm', 'common']);

    return (
        <>
            {/* ===============  breadcrumb area start =============== */}
            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="breadcrumb-wrap">
                                <h2>{t('title')}</h2>
                                <ul className="breadcrumb-links">
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/`}>{t('home', { ns: "common" })}</Link>
                                        <i className="bx bx-chevron-right" />
                                    </li>
                                    <li>{t('title')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===============  breadcrumb area end =============== */}
            <div className="contact-wrapper pt-90">
                <div className="contact-cards">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="contact-card">
                                    <div className="contact-icon"><i className="flaticon-customer-service" />
                                    </div>
                                    <div className="contact-info">
                                        <h5>{t('phone')}</h5>
                                        <p>+370 630 630 91</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="contact-card">
                                    <div className="contact-icon"><i className="flaticon-thumbs-up" />
                                    </div>
                                    <div className="contact-info">
                                        <h5>{t('socialMedia')}</h5>
                                        <ul className="contact-icons">
                                            <li>
                                                <a href={process.env.REACT_APP_FACEBOOK_URL} target="_blank" rel="noreferrer"><i className="bx bxl-facebook" /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="contact-inputs pt-100">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="contact-details">
                                    <h5 className="contact-d-head">{t("businessInfo")}</h5>
                                    <p>
                                        MB „Kelionių ABC“
                                        <br />
                                        {t("businessCode")}: 305933259
                                        <br />
                                        {t("bankAccount")}:  LT177300010170937903
                                        <br />
                                        {t("bank")}: AB Swedbank
                                        <br />
                                        {t("bankCode")}: 73000
                                        <br />
                                        <br />
                                        {t("otherInfo")}:
                                        <br />
                                        {t("licenseInfo")}
                                    </p>
                                    <ul className="office-clock">
                                        <li>
                                            <div className="clock-icon"><i className="flaticon-clock-1" /></div>
                                            <div className="clock-info">
                                                <h5>{t('openHours')}</h5>
                                                <p>Pirm - Šest. <br /> 08:00 - 22:00</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <RequestForm showDestinationInput={true}></RequestForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
