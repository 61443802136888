import { AxiosResponse } from 'axios';
import httpClient from '../clients/httpClient';
import PackageList from '../types/contracts/responses/PackageList';
import { toast } from 'react-toastify';
import PackageFilters from '../types/contracts/requests/PackageFilters';
import Package from '../types/contracts/responses/Package';
import CountryList from '../types/contracts/responses/CountryList';

class PackageService {
    baseUri: string = "/v1/Packages";

    constructFilterQueryParams = (filters: PackageFilters): string => {
        const queryParams = new URLSearchParams();

        for (const [key, value] of Object.entries(filters)) {
            if (value !== undefined && value !== null) {
                if (Array.isArray(value)) {
                    value.forEach((item) => queryParams.append(key, item));
                } else {
                    queryParams.append(key, String(value));
                }
            }
        }

        return queryParams.toString();
    };

    displayError = (e: any) => {
        if (e.response?.data?.message && e.response.status !== 500) {
            const data = e.response.data;
            let errorMessage = `${data.message}\n`;

            for (const key in data.details) {
              if (data.details.hasOwnProperty(key)) {
                const fieldErrors = data.details[key];
                errorMessage += `${key}: ${fieldErrors.join(', ')}\n`;
              }
            }

            toast.error(errorMessage);
        }
        else {
            toast.error("Serverio klaida. Prašome bandyti vėliau.");
        }
    }

    async getAll(filters: PackageFilters): Promise<PackageList | null> {
        try {
            const query: string = this.constructFilterQueryParams(filters);
            const response: AxiosResponse = await httpClient.get(`${this.baseUri}/active?${query}`);
            const list: PackageList = {
                packages: response.data,
                totalCount: parseInt(response.headers['total-count']!)
            }

            return list;
        }
        catch (e: any) {
            this.displayError(e);
        }

        return null;
    }

    async getAvailableCountries(limit: number, offset: number): Promise<CountryList | null> {
        try {
            const response: AxiosResponse = await httpClient.get(`${this.baseUri}/countries?limit=${limit}&offset=${offset}`);
            const list: CountryList = {
                countryCodes: response.data,
                totalCount: parseInt(response.headers['total-count']!)
            }

            return list;
        }
        catch (e: any) {
            this.displayError(e);
        }

        return null;
    }

    async getById(id: string): Promise<Package | null> {
        try {
            const response: AxiosResponse = await httpClient.get(`${this.baseUri}/${id}`);
            return response.data;
        }
        catch (e: any) {
            this.displayError(e);
        }

        return null;
    }

    getDiscountedPrice(travelPackage: Package): number {
        if (!travelPackage.discount) {
            return travelPackage.price;
        }

        const discountedPrice = travelPackage.price - travelPackage.price * travelPackage.discount / 100;
        return  Math.round((discountedPrice + Number.EPSILON) * 100) / 100
    }
}

const newPackageService = new PackageService();
export default newPackageService;
