import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import ROUTES from './constants/ROUTES';
import MainLayout from './components/layouts/main';
import ContactPage from './components/pages/contact/ContactPage';
import NotFound from './components/pages/404/NotFound';
import { Suspense } from 'react';
import Loader from './components/common/loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ExternalRedirect from './ExternalRedirect';
import PackagePage from './components/pages/package/PackagePage';
import IsoCountries from "i18n-iso-countries";
import PackageDetailsPage from './components/pages/package/PackageDetailsPage';

function App() {
  IsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
  IsoCountries.registerLocale(require("i18n-iso-countries/langs/lt.json"));

  return (
    <>
      <Router>
        <Suspense fallback={<Loader />}>
          <MainLayout>
            <Routes>
              <Route path={ROUTES.HOME} element={<PackagePage />} />
              <Route path={ROUTES.PACKAGES} element={<PackagePage />} />
              <Route path={`${ROUTES.PACKAGES}/:id`} element={<PackageDetailsPage />} />
              <Route path={ROUTES.OFFER_REQUEST} element={<ContactPage />} />
              <Route path={ROUTES.FACEBOOK} element={<ExternalRedirect url={process.env.REACT_APP_FACEBOOK_URL!} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </MainLayout>
        </Suspense>
      </Router>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
