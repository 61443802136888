import React from 'react';
import { Link } from 'react-router-dom';

interface PaginationProps {
    rowsPerPage: number;
    page: number;
    totalCount: number;
    setPage: React.Dispatch<React.SetStateAction<number>>,
}

const Pagination: React.FC<PaginationProps> = ({ rowsPerPage, page, totalCount, setPage }) => {
    const totalPages = Math.ceil(totalCount / rowsPerPage);

    const navigateBack = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    const navigateForward = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    }

    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 4;

        if (totalPages <= maxPagesToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <Link to={`?page=${i}`} key={i} className={i === page ? 'active' : ''} onClick={() => setPage(i)}>
                        {i}
                    </Link>
                );
            }
        } else {
            const minPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
            const maxPage = Math.min(totalPages, minPage + maxPagesToShow - 1);

            if (maxPage - minPage + 1 < maxPagesToShow) {
                const diff = maxPagesToShow - (maxPage - minPage + 1);
                pageNumbers.push(
                    <Link to={`#${totalPages}`} key={totalPages}>
                        {totalPages}
                    </Link>
                );
                for (let i = totalPages - diff; i < totalPages; i++) {
                    pageNumbers.unshift(
                        <Link to={`?page=${i}}`} key={i} className={i === page ? 'active' : ''} onClick={() => setPage(i)}>
                            {i}
                        </Link>
                    );
                }
            } else {
                for (let i = minPage; i <= maxPage; i++) {
                    pageNumbers.push(
                        <Link to={`?page=${i}`} key={i} className={i === page ? 'active' : ''} onClick={() => setPage(i)}>
                            {i}
                        </Link>
                    );
                }
            }
        }

        return pageNumbers;
    };

    return (
        <div className="pagination mt-40">
            <Link to={page > 1 ? `?page=${page - 1}` : "#"} onClick={navigateBack}>
                <i className="bx bx-chevron-left" />
            </Link>
            {getPageNumbers()}
            <Link to={page < totalPages ? `?page=${page + 1}` : "#"} onClick={navigateForward}>
                <i className="bx bx-chevron-right" />
            </Link>
        </div>
    );
};

export default Pagination;
