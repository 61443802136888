import axios, { AxiosError } from "axios";

const instance = axios.create();

instance.interceptors.response.use(function (response) {
    return response;
}, function (error : AxiosError) {
    return Promise.reject(error);
});

export default instance
