export default function Loader() {
    const rows = [];
    for (let i = 1; i <= 20; i++) {
        rows.push(<span key={i} style={{ ["--i" as any]: i }} />);
    }

    return (
        <div className="preloader" >
            <div className="loader loader1">
                {rows}
                <div className="rocket" />
            </div>
        </div >
    )
}
