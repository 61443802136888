import { useTranslation } from 'react-i18next';
import httpClient from "../../../clients/httpClient";
import { toast } from 'react-toastify';
import React from "react";
import '../../../assets/css/checkMark.css';
import ReCAPTCHA from "react-google-recaptcha";

interface RequestFormProps {
    showDestinationInput: boolean,
    packageId?: string
}

export default function RequestForm(props: RequestFormProps) {
    const { t } = useTranslation(['requestForm', 'common']);

    const [requestSent, setRequestSent] = React.useState<boolean>(false);
    const recaptchaRef = React.useRef<ReCAPTCHA>(null);

    const checkMarkRef = React.useRef<HTMLInputElement>(null);
    React.useEffect(() => {
        if (requestSent && checkMarkRef.current) {
            checkMarkRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            });
        }
    }, [requestSent]);

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        const target = event.target as typeof event.target & {
            destinationInput: { value: string } | undefined;
            tripDurationInput: { value: string };
            tripDateStartInput: { value: string };
            tripDateEndInput: { value: string };
            budgetInput: { value: number };
            passengerInput: { value: number };
            nameInput: { value: number };
            phoneInput: { value: number };
            emailInput: { value: number };
            packageId: { value: string } | undefined;
        };

        const recaptchResponse = recaptchaRef.current?.getValue();

        if (recaptchResponse === null || recaptchResponse === "") {
            toast.error(t("captchaNotCompleted", { ns: 'common' }));
            return;
        }

        try {
            await httpClient.post(`/v1/OfferRequests`, {
                "destination": target.destinationInput ? target.destinationInput.value : 'N/A',
                "durations": target.tripDurationInput.value,
                "startDate": target.tripDateStartInput.value,
                "endDate": target.tripDateEndInput.value,
                "budget": target.budgetInput.value,
                "passengerCountDesciption": target.passengerInput.value,
                "name": target.nameInput.value,
                "phone": target.phoneInput.value,
                "email": target.emailInput.value,
                "packageId": props.packageId,
                "recaptchaResponse": recaptchaRef.current?.getValue()
            });

            setRequestSent(true);
        }
        catch (e: any) {
            console.log(e);
            if (e.response?.data?.message)
                toast.error(e.response.data.message);
            else
                toast.error(t("unexpectedError", { ns: 'common' }));
        }
    }

    return (
        <>
            {
                requestSent ?
                    <div ref={checkMarkRef}>
                        <svg className="checkMark" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                        </svg>
                        <p className="checkMarkText success">{t("successStatusMsg")}</p>
                    </div>
                    :
                    <div className="contact-form" onSubmit={handleSubmit}>
                        <form action="#checkMark" >
                            <h5 className="contact-d-head">{t('title')}</h5>
                            <div className="row">
                                {
                                    props.showDestinationInput &&
                                    <div className="form-group">
                                        <label htmlFor="destinationInput">{t("destination")}</label>
                                        <input type="text" className="form-control" id="destinationInput" aria-describedby="destinationHelp" placeholder="" required />
                                        <small id="destinationHelp" className="form-text text-muted">{t("destinationTip")}</small>
                                    </div>
                                }

                                <div className="form-group">
                                    <label htmlFor="tripDurationInput">{t("tripDuration")}</label>
                                    <input type="text" className="form-control" id="tripDurationInput" placeholder="5, 7, 9, 10" required />
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="tripDateStartInput">{t("tripDateStart")}</label>
                                        <input type="date" className="form-control" id="tripDateStartInput" aria-describedby="tripDateStartHelp" required />
                                        <small id="tripDateStartHelp" className="form-text text-muted">{t("tripDateStartTip")}</small>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="tripDateEndInput">{t("tripDateEnd")}</label>
                                        <input type="date" className="form-control" id="tripDateEndInput" aria-describedby="tripDateEndHelp" required />
                                        <small id="tripDateEndHelp" className="form-text text-muted">{t("tripDateEndTip")}</small>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="budgetInput">{t("budget")}</label>
                                    <input type="number" min="1" step="0.01" className="form-control" id="budgetInput" required />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="passengerInput">{t("passengerCount")}</label>
                                    <input type="number" min="1" className="form-control" id="passengerInput" required />
                                </div>

                                <h5 className="contact-d-head">{t('contactInfo')}</h5>

                                <div className="form-group">
                                    <label htmlFor="nameInput">{t("name")}</label>
                                    <input type="text" className="form-control" id="nameInput" required />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="phoneInput">{t("phone")}</label>
                                    <input type="phone" className="form-control" id="phoneInput" required />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="emailInput">{t("email")}</label>
                                    <input type="emailInput" className="form-control" id="emailInput" required />
                                </div>

                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY ?? (() => { throw new Error("ReCaptcha site key not provided") })()}
                                />

                                <div className="col-lg-12 mb-3">
                                    <input type="submit" value={t("send").toString()} />
                                </div>
                            </div>
                        </form>
                    </div>
            }
        </>
    );
}
