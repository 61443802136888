import { AxiosResponse } from 'axios';
import httpClient from '../clients/httpClient';
import CategoryList from '../types/contracts/responses/CategoryList';
import { toast } from 'react-toastify';

class CategoryService {
    baseUri: string = "/v1/Categories";

    displayError = (e: any) => {
        if (e.response?.data?.message && e.response.status !== 500) {
            const data = e.response.data;
            let errorMessage = `${data.message}\n`;

            for (const key in data.details) {
              if (data.details.hasOwnProperty(key)) {
                const fieldErrors = data.details[key];
                errorMessage += `${key}: ${fieldErrors.join(', ')}\n`;
              }
            }

            toast.error(errorMessage);
        }
        else {
            toast.error("Serverio klaida. Prašome bandyti vėliau.");
        }
    }

    async getAll(limit: number, offset: number, searchQuery?: string): Promise<CategoryList | null> {
        try {
            let query = `${limit}&offset=${offset}`;
            if (searchQuery) {
                query += `&${searchQuery}`;
            }

            const response: AxiosResponse = await httpClient.get(`${this.baseUri}?${query}`);
            const categoryList: CategoryList = {
                categories: response.data,
                totalCount: parseInt(response.headers['total-count']!)
            }

            return categoryList;
        }
        catch (e: any) {
            this.displayError(e);
        }

        return null;
    }
}

const newCategoryService = new CategoryService();
export default newCategoryService;
