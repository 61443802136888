enum ROUTES {
    HOME = '/',
    CONTACT = "/contact",
    OFFER_REQUEST = "offerRequest",
    FACEBOOK = "facebook",
    PACKAGES = "packages",
    NOT_FOUND = "404"
}

export default ROUTES;
