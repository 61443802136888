import * as React from 'react';
import { Link, useLocation } from "react-router-dom";

// Using an ES6 transpiler like Babel
import Slider from 'react-rangeslider';
// To include the default styles
import 'react-rangeslider/lib/index.css';
import Package from '../../../types/contracts/responses/Package';
import PackageFilters from '../../../types/contracts/requests/PackageFilters';
import PackageService from '../../../services/PackageService';
import Pagination from '../../common/Pagination';
import CategoryList from '../../../types/contracts/responses/CategoryList';
import CategoryService from '../../../services/CategoryService';
import CountryList from '../../../types/contracts/responses/CountryList';
import IsoCountries from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import useFirstRender from '../../common/useFirstRender';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PackagePage = () => {
    const { t, i18n } = useTranslation(['packages', 'common']);
    const getLanguage = (): string => i18n.language.toLowerCase() === "en-us" ? "en" : i18n.language;
    const firstRender = useFirstRender();

    const maxPrice: number = 10000;

    let query = useQuery();
    const pageParam: number = parseInt(query.get("page") ?? '1');
    const defaultMaxPrice: number = parseInt(query.get("maxPrice") ?? `${maxPrice}`);
    const defaultCountries: string[] = query.get("countryCodes")?.split(',') ?? [];
    const defaultCategories: string[] = query.get("categories")?.split(',') ?? [];
    const defaultSearchQuery: string | undefined = query.get("search") ?? undefined;

    const [page, setPage] = React.useState<number>(pageParam);
    const rowsPerPage: number = 10;
    const [filters, setFilters] = React.useState<PackageFilters>({
        limit: rowsPerPage,
        offset: (pageParam - 1) * rowsPerPage,
        maxPrice: defaultMaxPrice,
        countryCodes: defaultCountries.length === 0 ? undefined : defaultCountries.join(","),
        categories: defaultCategories.length === 0 ? undefined : defaultCategories.join(","),
        searchQuery: defaultSearchQuery
    });

    const [travelPackages, setTravelPackages] = React.useState<Package[]>([]);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

    const setUrlQuery = () => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        params.set('page', page.toString());

        if (filters.maxPrice) {
            params.set('maxPrice', filters.maxPrice.toString());
        }
        else {
            params.delete('maxPrice')
        }

        if (filters.countryCodes) {
            params.set('countryCodes', filters.countryCodes);
        }
        else {
            params.delete('countryCodes');
        }

        if (filters.categories) {
            params.set('categories', filters.categories);
        }
        else {
            params.delete('categories');
        }

        if (filters.searchQuery) {
            params.set('search', filters.searchQuery);
        }
        else {
            params.delete('search');
        }

        const newUrl = `${window.location.origin}${window.location.pathname}?${params.toString()}`;

        window.history.replaceState(null, '', newUrl);
    }

    React.useEffect(() => {
        const getItems = async () => {
            setDataLoaded(false);
            const list = await PackageService.getAll(filters);
            if (list != null) {
                setTravelPackages(list.packages);
                setTotalCount(list.totalCount);
                setUrlQuery();
            }
            setDataLoaded(true);
        }

        getItems();
    }, [filters]);

    React.useEffect(() => {
        const changeOffset = async (offset: number) => {
            setFilters((prevFilters) => ({ ...prevFilters, offset }));
        }

        if (!firstRender) {
            changeOffset((page - 1) * rowsPerPage);
        }
    }, [page, setFilters, firstRender]);


    const [priceSliderValue, setPriceSliderValue] = React.useState<number>(defaultMaxPrice);

    const handlePriceSliderChange = (value: number) => {
        setPriceSliderValue(value);
    };

    const handlePriceSliderChangeComplete = () => {
        setPriceSliderValue(priceSliderValue);
        setFilters({ ...filters, maxPrice: priceSliderValue });
    };

    const [categories, setCategories] = React.useState<string[]>([]);
    const [categoriesLoaded, setCategoriesLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
        const getItems = async () => {
            setCategoriesLoaded(false);
            const list: CategoryList | null = await CategoryService.getAll(100, 0);
            if (list != null) {
                setCategories(list.categories.map(category => category.name));
            }
            setCategoriesLoaded(true);
        }

        getItems();
    }, []);

    const [checkedCategories, setCheckedCategories] = React.useState<string[]>(defaultCategories);
    const handleCheckboxChange = (categoryName: string, active: boolean) => {
        if (active) {
            if (!checkedCategories.includes(categoryName)) {
                const newCategories = [...checkedCategories, categoryName];
                setCheckedCategories(newCategories);
                setFilters((prevFilters) => ({ ...prevFilters, categories: newCategories.join(',') }));
            }
        } else {
            const newCategories = checkedCategories.filter((name) => name !== categoryName);
            setCheckedCategories(newCategories);
            setFilters((prevFilters) => ({ ...prevFilters, categories: newCategories.length === 0 ? undefined : newCategories.join(',') }));
        }
    };

    const [countryCodes, setCountryCodes] = React.useState<string[]>([]);
    const [countriesLoaded, setCountriesLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
        const getItems = async () => {
            setCountriesLoaded(false);
            const list: CountryList | null = await PackageService.getAvailableCountries(100, 0);
            if (list != null) {
                setCountryCodes(list.countryCodes);
            }
            setCountriesLoaded(true);
        }

        getItems();
    }, []);

    const [checkedCountries, setCheckedCountries] = React.useState<string[]>(defaultCountries);
    const handleCountryCheckboxChange = (countryCode: string, active: boolean) => {
        if (active) {
            if (!checkedCountries.includes(countryCode)) {
                const newCountries = [...checkedCountries, countryCode];
                setCheckedCountries(newCountries);
                setFilters((prevFilters) => ({ ...prevFilters, countryCodes: newCountries.join(',') }));
            }
        } else {
            const newCountries = checkedCountries.filter((code) => code !== countryCode);
            setCheckedCountries(newCountries);
            setFilters((prevFilters) => ({ ...prevFilters, countryCodes: newCountries.length === 0 ? undefined : newCountries.join(',') }));
        }
    };

    const searchPackages = React.useMemo(
        () => debounce((newValue: string) => setFilters((prevFilters) => ({ ...prevFilters, searchQuery: newValue ?? undefined })), 1000),
        []
    );

    return (
        <>
            {/* ===============  breadcrumb area start =============== */}
            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="breadcrumb-wrap">
                                <h2>{t('travelPackages')}</h2>
                                <ul className="breadcrumb-links">
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/`}>{t('home', { ns: "common" })}</Link>
                                        <i className="bx bx-chevron-right" />
                                    </li>
                                    <li>{t('travelPackages')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===============  breadcrumb area end =============== */}
            {/* ===============  Package  area start =============== */}
            <div className="package-standard-wrapper pt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row mb-30">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="package-filter">
                                        <h5>{t('showingResults', { ns: 'common', numberRange: `${(page - 1) * rowsPerPage + Math.min(1, travelPackages.length)}-${Math.min(totalCount, page * rowsPerPage)}`, totalResultCount: `${totalCount}` })}</h5>
                                    </div>
                                </div>
                                {/* <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="custom-select package-option package-selected-standard">
                                        <select>
                                            <option value={0}>Package 1</option>
                                            <option value={1}>Package 2</option>
                                            <option value={2}>Package 3</option>
                                            <option value={3}>Package 4</option>
                                        </select>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row">

                                {
                                    dataLoaded &&
                                    travelPackages.map((row: Package) =>
                                        <div className="col-lg-12 col-md-12" key={row.id}>
                                            <div className="package-card-xl">
                                                <div className="package-thumb-xl">
                                                    <Link to={`${process.env.PUBLIC_URL}/packages/${row.id}`}>
                                                        <div className="image-container">
                                                            {
                                                                row.discount !== undefined && row.discount > 0 &&
                                                                <div className="circle">
                                                                    {-row.discount}%
                                                                </div>
                                                            }
                                                            <img src={row.thumbnailImageUrl} alt="" className="img-fluid" />
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="package-details-xl">
                                                    <div className="package-info">
                                                        <h5>{t('from', { ns: 'common' })} {
                                                            (row.discount !== undefined && row.discount > 0) ? <><span className="primary">{PackageService.getDiscountedPrice(row)}€</span> <s>{row.price}€</s></>
                                                                : `${row.price}€`
                                                        } {t('perPerson')}</h5>
                                                        <h5><i className="flaticon-calendar" />{row.totalDays} {t('days')}</h5>
                                                    </div>
                                                    <h3><i className="flaticon-arrival" />
                                                        <Link to={`${process.env.PUBLIC_URL}/packages/${row.id}`}>{IsoCountries.getName(row.countryCode, getLanguage())}, {row.name}</Link>
                                                    </h3>
                                                    <p>{row.shortDescription}</p>
                                                    {/* <div className="package-rating">
                                                        <strong><i className="bx bxs-star" /><span>8K+</span> Rating</strong>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="row">
                                <Pagination rowsPerPage={rowsPerPage} page={page} totalCount={totalCount} setPage={setPage} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="package-sidebar">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="sidebar-searchbox">
                                            <div className="input-group search-box">
                                                <input type="text" className="form-control" placeholder={t('search', { ns: 'common' })}
                                                    aria-describedby="button-addon2"
                                                    defaultValue={defaultSearchQuery}
                                                    onChange={(e) => { searchPackages(e.target.value); }}
                                                />
                                                <button className="btn btn-outline-secondary" type="button"><i className="bx bx-paper-plane" /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="sidebar-range mt-40">
                                            <h5 className="categorie-head">{t('price')}</h5>
                                            <div className='slider'>
                                                <Slider
                                                    min={0}
                                                    max={maxPrice}
                                                    value={priceSliderValue}
                                                    onChange={handlePriceSliderChange}
                                                    onChangeComplete={handlePriceSliderChangeComplete}
                                                />
                                                <div className='value'>€{priceSliderValue}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="sidebar-duration mt-40">
                                            <h5 className="categorie-head">{t('countries')}</h5>
                                            <div className="durations-option radio-box">
                                                {
                                                    countriesLoaded &&
                                                    countryCodes.map((row: string, index: number) =>
                                                        <label className="checkboxContainer" key={`country${index}`}>
                                                            {IsoCountries.getName(row, getLanguage())}
                                                            <input type="checkbox" name="countryCode"
                                                                checked={checkedCountries.includes(row)}
                                                                onChange={(e) => handleCountryCheckboxChange(row, e.target.checked)}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="sidebar-categorie mt-40">
                                            <h5 className="categorie-head">{t('categories')}</h5>
                                            <div className="durations-option radio-box">
                                                {
                                                    categoriesLoaded &&
                                                    categories.map((row: string, index: number) =>
                                                        <label className="checkboxContainer" key={`category${index}`}>
                                                            {row}
                                                            <input type="checkbox" name="category"
                                                                checked={checkedCategories.includes(row)}
                                                                onChange={(e) => handleCheckboxChange(row, e.target.checked)}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-12 col-md-6">
                                        <div className="sidebar-banner mt-40">
                                            <img src={sidebarBanner} alt="" className="img-fluid" />
                                            <div className="sidebar-banner-overlay">
                                                <div className="overlay-content">
                                                    <h3>Get 50% Off
                                                        In Dubai Tour</h3>
                                                    <div className="sidebar-banner-btn">
                                                        <Link to={"#"} >Book Now</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ===============  Package  area end =============== */}
        </>
    );
}

export default PackagePage;
